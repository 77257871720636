* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
/*  font-family: "Roboto", "Helvetica", "Arial", "sans-serif"; */
  font-family: Meiryo, sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}

a:link {
  color: #9E9E9E;
  text-decoration: none;
}

a:hover {
  color: #2962FF;
}

.noscrollbar::-webkit-scrollbar {
  display: none;
}

.layout-pane::-webkit-scrollbar {
  display: none;
}