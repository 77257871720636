.splitter-layout > .layout-splitter {
  flex: 0 0 auto;
  width: 2px;
  height: 100%;
  cursor: col-resize;
  background-color: #dedede;
}

.splitter-layout.splitter-layout-vertical > .layout-splitter {
  width: 100%;
  height: 2px;
  cursor: row-resize;
}